import {
  DsBox,
  DsHeader,
  DsImage,
  DsStack,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import { IWithRouterProps } from '../Lib/withRouter'
import withPerformHandshake from '../Hocs/withPerformHandshake'
import MobileHeader from '../Components/MobileHeader/MobileHeader'
import Sidebar from '../Components/Sidebar/Sidebar'
import { HEADER_ASSETS } from '../Constants/ASSET_MAP'
import { ThunkDispatch } from '@reduxjs/toolkit'
import withErrorConnect from '../Lib/withErrorConnect'
import getCustomerProfileDetailsAction from '../Redux/Customer/Services/getCustomerProfileDetailsAction'
import { USE_CLEVERTAP } from '~/src/Configurations/env'

type ActionTypes = {
  getCustomerProfileDetails: ()=>any
}
export interface IMainLayoutProps extends IWithRouterProps, IwithBreakpoints {
  actions: ActionTypes
  searchParams: any
  tabValue: number
}

class MainLayout extends Component<IMainLayoutProps> {
  handleShowMobileLayout = () => {
    const url = window.location.href
    const pathname = new URL(url).pathname
    if (pathname.includes('quotes')) {
      return false
    }
    // return !FULL_SCREEN.includes(pathname)
    return false
  }
  handleGetCustomerProfileDetails = async () => {
    const { actions } = this.props
    const response = await actions.getCustomerProfileDetails()
    if (response._isCustomError) {
      // return handleError(response)
      console.log('failed API - getCustomerProfileDetails')
    } else {
      if (USE_CLEVERTAP === 'true') {
        {
          const { name, email, phoneNo } = response
            ; (window as any)?.clevertap?.profile?.push({
              Site: {
                Name: name,
                Identity: phoneNo,
                Email: email,
                Phone: phoneNo
              }
            })
        }
      }
    }
  }

  render() {
    const { breakpoints = {} } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false
    return (
      <DsStack>
        {isDesktop && (
          <DsStack
            sx={{
              position: 'fixed',
              width: '100%',
              top: 0,
              zIndex: 2,
              height:
                'calc(var(--ds-spacing-superheated) + var(--ds-spacing-quickFreeze))'
            }}
          >
            <DsHeader
              sx={{
                display: 'flex',
                position: 'absolute',
                height: {
                  md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-quickFreeze))'
                }
              }}
              logo={
                <DsBox>
                  <DsImage
                    srcSet={HEADER_ASSETS}
                    style={{
                      width: '100%'
                      // height: '100%'
                    }}
                  />
                </DsBox>
              }
            />
          </DsStack>
        )}

        <DsStack
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            display: { md: 'none', xs: 'flex' }
          }}
        >
          <MobileHeader />
        </DsStack>

        <DsStack
          direction="row"
          spacing={isDesktop ? 'var(--ds-spacing-mild)' : 0}
          sx={{
            justifyContent: 'flex-end',
            mt: {
              md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-warm))'
            },
            mr: { md: 'var(--ds-spacing-mild)' },
            mb: { md: 'var(--ds-spacing-mild)' },
            ml: {
              md: 'var(--ds-spacing-mild)'
            }
          }}
        >
          {isDesktop && (
            <DsBox
              sx={{
                width: { lg: '448px', md: '38%' },
                height: isDesktop ? '100vh' : 'calc(100vh - 72px)',
                display: { md: 'flex', xs: 'none' },
                background: 'white',
                flexDirection: 'column',
                position: 'fixed',
                left: 'var(--ds-spacing-mild)'
              }}
            >
              <Sidebar />
            </DsBox>
          )}

          <DsBox
            sx={{
              width: {
                xs: '100%',
                md: 'calc(62% - 48px)',
                lg: 'calc(100% - 470px)'
              }
            }}
          >
            <Outlet />
          </DsBox>
        </DsStack>
      </DsStack>
    )
  }
}

// export default withBreakpoints(withPerformHandshake(MainLayout))
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    getCustomerProfileDetails: () =>
      dispatch(getCustomerProfileDetailsAction())
  }
})
const mapStateToProps = () => { }

export default (
  withErrorConnect(mapStateToProps, mapDispatchToProps)(withBreakpoints(MainLayout))
)