export const APP_TITLE: any = process.env.APP_TITLE
export const APP_DESCRIPTION = process.env.APP_DESCRIPTION
export const APP_PRECONNECT_DOMAINS = process.env.APP_PRECONNECT_DOMAINS

export const AS_API_DOMAIN = process.env.API_DOMAIN
export const AS_API_KEY: any = process.env.API_KEY
export const AS_ENABLE_CRYPTOGRAPHY = process.env.ENABLE_CRYPTOGRAPHY
export const AS_API_TIMEOUT: any = process.env.API_TIMEOUT
export const AS_GALAXY_API_DOMAIN: any = process.env.GALAXY_API_DOMAIN

export const AS_X_API_CLIENT_ID = process.env.X_API_CLIENT_ID
export const AS_X_SERVICE_NAME = process.env.X_SERVICE_NAME
export const AS_AUTHORIZATION = process.env.AUTHORIZATION
export const AS_X_SUBACCOUNT_ID = process.env.X_SUBACCOUNT_ID
export const AS_SELF_DOMAIN = process.env.SELF_DOMAIN
export const AS_X_API_ENCRYPTION_KEY = process.env.X_API_ENCRYPTION_KEY
export const AS_MAX_ALLOWED_CONTRACTS = Number(
  process.env.MAX_ALLOWED_CONTRACTS
)

export const COMMON_HEADERS = {
  authorization: AS_AUTHORIZATION,
  // 'X-API-Client-Id': AS_X_API_CLIENT_ID,
  'X-SERVICE-NAME': AS_X_SERVICE_NAME
  // "X-SubAccountID": AS_X_SUBACCOUNT_ID,
  // "X-API-Encryption-Key": AS_X_API_ENCRYPTION_KEY,
}

export const CLEVERTAP_ACCOUNT_ID = process.env.CLEVERTAP_ACCOUNT_ID
export const USE_CLEVERTAP = process.env.USE_CLEVERTAP