import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  HOME: {
    pathname: '/'
  } as AppRouteItem,
  LEDGER_REPORTS: {
    pathname: '/ledger-report'
  } as AppRouteItem,
  CONTRACT_NOTE: {
    pathname: '/contract-notes'
  } as AppRouteItem,
  ERROR: {
    pathname: '/error'
  } as AppRouteItem,
  SOMETHING_WENT_WRONG: {
    pathname: '/something-went-wrong'
  } as AppRouteItem,
  ANY: {
    pathname: '*'
  } as AppRouteItem
}

export const getAppRouteByKey = (key: string): AppRouteItem => {
  return APP_ROUTES[key as keyof typeof APP_ROUTES]
}

export default APP_ROUTES
