import React from 'react'
import { DsSkeleton, DsStack } from '@am92/react-design-system'

class ContractNoteSkeleton extends React.PureComponent {
  render() {
    return (
      <>
        <DsStack spacing={12}>
          <DsStack>
            <DsSkeleton
              variant='rounded'
              sx={{ width: '100%', height: '80px' }}
            />
          </DsStack>
          <DsStack spacing={8}>
            <DsStack>
              <DsSkeleton
                variant='rounded'
                sx={{ width: '100%', height: '40px' }}
              />
            </DsStack>
            <DsStack spacing={4}>
              <DsSkeleton
                variant='rounded'
                sx={{ width: '100%', height: '40px' }}
              />
              <DsSkeleton
                variant='rounded'
                sx={{ width: '100%', height: '40px' }}
              />
              <DsSkeleton
                variant='rounded'
                sx={{ width: '100%', height: '40px' }}
              />
              <DsSkeleton
                variant='rounded'
                sx={{ width: '100%', height: '40px' }}
              />
            </DsStack>
          </DsStack>
        </DsStack>
      </>
    )
  }
}

export default ContractNoteSkeleton
