import WebHttp, {
  WEB_HTTP_CONTEXT,
  WebHttpAxiosConfig,
  WebHttpConfig
} from '@am92/web-http'
import {
  AS_API_DOMAIN,
  AS_API_KEY,
  AS_ENABLE_CRYPTOGRAPHY,
  AS_API_TIMEOUT,
  COMMON_HEADERS,
  AS_X_API_CLIENT_ID
} from '~/src/Configurations/env'

const AS_AXIOS_HTTP_CONFIG: WebHttpAxiosConfig = {
  baseURL: AS_API_DOMAIN,
  timeout: Number(AS_API_TIMEOUT),
  headers: { ...COMMON_HEADERS }
}

const AS_WEB_HTTP_CONFIG: WebHttpConfig = {
  disableCrypto: AS_ENABLE_CRYPTOGRAPHY === 'false'
}

export const asHttp = new WebHttp(AS_AXIOS_HTTP_CONFIG, AS_WEB_HTTP_CONFIG)
asHttp.context.set(WEB_HTTP_CONTEXT.API_KEY, AS_API_KEY || '')
asHttp.context.set(WEB_HTTP_CONTEXT.CLIENT_ID, AS_X_API_CLIENT_ID || '')

const AXIOS_HTTP_CONFIG = {
  baseURL: AS_API_DOMAIN,
  timeout: 30000,
  retries: 3,
  headers: { ...COMMON_HEADERS }
}
const WEB_HTTP_CONFIG = {
  disableCrypto: true,
  disableHeaderInjection: false
}

export const webHttp = new WebHttp(AXIOS_HTTP_CONFIG, WEB_HTTP_CONFIG)

webHttp.context.set(WEB_HTTP_CONTEXT.API_KEY, AS_API_KEY || '')
webHttp.context.set(WEB_HTTP_CONTEXT.CLIENT_ID, AS_X_API_CLIENT_ID || '')
// NOTE: todo reverify this code
if (!asHttp.context.get(WEB_HTTP_CONTEXT.PUBLIC_KEY)) {
  asHttp.context = webHttp.context
}

export const HANDSHAKE_ENABLED_HTTP = [webHttp]
