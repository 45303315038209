import * as React from 'react'
import {
  DsBox,
  DsButton,
  DsButtonGroup,
  DsButtonGroupProps,
  DsButtonProps,
  DsDivider,
  DsLink,
  DsStack,
  DsTypography,
  DsBottomSheet
} from '@am92/react-design-system'

interface IReachOut {
  contactNumbers: string[]
  emails: string[]
}

export interface IBottomStaggerProps {
  title: string
  descriptions: string[]
  primaryButtonLabel: string
  primaryButtonClick: () => void

  reachOut?: IReachOut
  buttonGroupSeperator?: string
  secondaryButtonLabel?: string
  secondaryButtonClick?: () => void
  onClose?: () => void

  buttonGroupProps?: DsButtonGroupProps
  primaryButtonProps?: Omit<DsButtonProps, 'color' | 'ref' | 'onClick'>
  secondaryButtonProps?: Omit<DsButtonProps, 'color' | 'ref' | 'onClick'>
}

const DBottomStaggerProps = {
  buttonGroupProps: {},
  primaryButtonProps: {},
  secondaryButtonProps: {},
  onClose: () => undefined
}

export default class BottomStagger extends React.PureComponent<IBottomStaggerProps> {
  static defaultProps = DBottomStaggerProps

  renderDescriptions = () => {
    const { descriptions } = this.props
    return descriptions.map(description => {
      return (
        <DsTypography
          color="text.secondary"
          variant="bodyRegularSmall"
          component={'div'}
        >
          {description.split('/').map(e => {
            return e.charAt(0) === '&' ? <b>{e.substring(1)}</b> : e
          })}
        </DsTypography>
      )
    })
  }

  renderReachOut = () => {
    const { reachOut } = this.props

    if (!reachOut) return null

    const { contactNumbers, emails } = reachOut
    const contactNumberJSX = contactNumbers.map((contactNumber, index) => (
      <>
        <DsLink underline="none" href={`tel:${contactNumber}`}>
          {contactNumber}
        </DsLink>
        {index < contactNumbers.length - 1 && (
          <DsTypography
            sx={{
              mx: 'var(--ds-spacing-glacial)'
            }}
            variant="bodyRegularSmall"
          >
            |
          </DsTypography>
        )}
      </>
    ))
    const emailJSX = emails.map((email, index) => (
      <>
        <DsLink underline="none" href={`mailto:${email}`}>
          {email}
        </DsLink>
        {index < emails.length - 1 && (
          <DsTypography
            sx={{
              mx: 'var(--ds-spacing-frostbite)'
            }}
            variant="bodyRegularSmall"
          >
            |
          </DsTypography>
        )}
      </>
    ))
    return (
      <DsStack direction="row" spacing="var(--ds-spacing-frostbite)">
        <DsStack spacing="var(--ds-spacing-quickFreeze)" sx={{ flexGrow: 1 }}>
          <DsTypography color="text.secondary" variant="supportRegularMetadata">
            Contact number
          </DsTypography>
          <DsBox sx={{ display: 'flex' }}>{contactNumberJSX}</DsBox>
        </DsStack>
        <DsStack spacing="var(--ds-spacing-quickFreeze)" sx={{ flexGrow: 1 }}>
          <DsTypography color="text.secondary" variant="supportRegularMetadata">
            Email ID
          </DsTypography>
          <DsTypography variant="bodyRegularSmall">{emailJSX}</DsTypography>
        </DsStack>
      </DsStack>
    )
  }

  renderButtonGroupSeperator = () => {
    const { buttonGroupSeperator } = this.props
    if (!buttonGroupSeperator) return null

    return (
      <DsDivider>
        <DsBox sx={{ px: 'var(--ds-spacing-frostbite)' }}>
          <DsTypography variant="supportRegularMetadata">
            {buttonGroupSeperator}
          </DsTypography>
        </DsBox>
      </DsDivider>
    )
  }

  render() {
    const {
      title,
      onClose,
      secondaryButtonLabel,
      primaryButtonLabel,
      secondaryButtonClick,
      secondaryButtonProps,
      primaryButtonProps,
      primaryButtonClick
    } = this.props
    return (
      <DsBottomSheet
        sx={{ display: { xs: 'block', md: 'none' } }}
        open
        secondaryButtonText={secondaryButtonLabel}
        primaryButtonText={primaryButtonLabel}
        primaryButtonProps={{
          ...primaryButtonProps,
          onClick: primaryButtonClick
        }}
        secondaryButtonProps={{
          ...secondaryButtonProps,
          onClick: secondaryButtonClick
        }}
        showClose
        onClose={onClose}
      >
        <DsStack spacing="var(--ds-spacing-mild)" sx={{ textAlign: 'left' }}>
          <DsTypography variant="headingBoldMedium" component={'div'}>
            {title}
          </DsTypography>
          <DsBox
            sx={{
              p: 'var(--ds-spacing-glacial)',
              backgroundColor: 'var(--ds-colour-surfaceSecondary)',
              borderRadius: '8px'
            }}
          >
            <DsStack spacing="var(--ds-spacing-cool)">
              {this.renderDescriptions()}
            </DsStack>
          </DsBox>
          {this.renderReachOut()}
          {this.renderButtonGroupSeperator()}
        </DsStack>
      </DsBottomSheet>
    )
  }
}
