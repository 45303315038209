import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'

export interface Deivce {
  deviceName: any
  osType: any
}
const INITIAL_STATE: Deivce = {
  deviceName: null,
  osType: null
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDeviceDetails: (state, action) => {
      const { osType, deviceName } = action.payload
      state.deviceName = deviceName
      state.osType = osType
    }
  }
}

const DeivceSlice = createSlice(sliceOptions)

export const { setDeviceDetails } = DeivceSlice.actions

export default DeivceSlice.reducer
