export const WHATS_CONST = {
  LEDGER: {
    title: 'Ledger Report',
    discription:
      'Ledger report includes details of all your transaction and billing activity along with the account summary.'
  },
  CONTRACT: {
    title: 'Contract Notes',
    discription:
      'A Contract Notes serves as a legal document of your online and offline transactions done on a particular day.'
  }
}
