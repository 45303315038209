import React from 'react'
import {
  NavigateFunction,
  NavigateOptions,
  Params,
  SetURLSearchParams,
  To,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
export interface IWithRouterProps {
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
  navigate: NavigateFunction
  location: Location
  params: Readonly<Params<string>>
  navigateTo: (route: To | number, options?: NavigateOptions) => void
}
export default function withRouter(
  Child: React.ElementType<any>
): React.ElementType<any> {
  return (props: any): React.JSX.Element => {
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = useParams()
    const navigateTo = (route: To | number, options?: NavigateOptions) => {
      if (typeof route === 'number') {
        navigate(route)
      } else {
        navigate(route, options)
      }
    }

    return (
      <Child
        {...props}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        navigate={navigate}
        location={location}
        params={params}
        navigateTo={navigateTo}
      />
    )
  }
}

export interface WithRouter {
  setSearchParams: () => void
  navigate: () => void
  navigateTo: (route: To | number, options?: NavigateOptions) => void
  searchParams: any
  location: any
  params: any
}
