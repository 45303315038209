import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from '~/src/Redux/Customer/Selectors'
import {
  getCustomerProfileDetailsActions,
} from '~/src/Redux/Customer/Actions'
import { _groupBy } from '~/src/Utils/lodash'

const INITIAL_STATE = {
  customerProfile: {},
  bankbalance: {},
  emarginDetails: {},
  partners: {},
  reports: [],
  selectedPartner: ''
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    resetEmarginData: (state, action) => {
      state.emarginDetails = {}
    },

    setCustomerEmargin: (state, action) => {
      const { payload } = action
      state.customerProfile['productsAllowed']['emargin']['isActive'] = payload
    },
    updateSelectedPartner: (state, action) => {
      state.selectedPartner = action.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getCustomerProfileDetailsActions.success,
      (state, { payload }) => {
        state.customerProfile = payload
      }
    )
  }
}

const slice = createSlice(sliceOptions)

export const { resetEmarginData, setCustomerEmargin, updateSelectedPartner } =
  slice.actions

export default slice.reducer
