export const BULB = [
  {
    src: new URL('~/public/assets/images/Bulb.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const NO_DATA = [
  {
    src: new URL('~/public/assets/images/NoData.svg', import.meta.url).href,
    alt: 'Home Image'
    // as: 'image/svg+xml'
  }
]

export const DATE_RANGE = [
  {
    src: new URL('~/public/assets/images/dateRange.svg', import.meta.url).href,
    alt: 'Home Image'
    // as: 'image/svg+xml'
  }
]
export const ARROW_UP = [
  {
    src: new URL('~/public/assets/images/arrow-up.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ARROW_DOWN = [
  {
    src: new URL('~/public/assets/images/arrow-down.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SOMETHING_WENT_WRONG = [
  {
    src: new URL(
      '~/public/assets/images/somethingWentWrong.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const HEADER_ASSETS = [
  {
    src: new URL('~/public/assets/images/Layouts/header.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
