import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const getCustomerProfileDetailsServiceName: any =
  'auth/getCustomerProfileDetails'
export const getCustomerProfileDetailsActions: any = {
  loading: createAction<any, any>(
    `${getCustomerProfileDetailsServiceName}/LOADING`
  ),
  success: createAction<any, any>(
    `${getCustomerProfileDetailsServiceName}/SUCCESS`
  ),
  error: createAction<any, any>(`${getCustomerProfileDetailsServiceName}/ERROR`)
}

