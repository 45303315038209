import Bowser from 'bowser'
import AppStore from '../Configurations/AppStore'
import { format } from 'date-fns'
import ApplicationMeta from '../../package.json'
import { USE_CLEVERTAP } from '~/src/Configurations/env'

export const getSubAccountIdFromRedux = () => {
  const store = AppStore.getState()

  const { ssoValidation = {} } = store.sso
  const { metadata = {} } = ssoValidation
  const { subAccountId = '' } = metadata
  return subAccountId
}

export const getRequiredDataForHeaders = () => {
  return {
    'X-SubAccountID': getSubAccountIdFromRedux(),
    'X-SourceChannel': 'galaxy',
    'x-authtoken': ''
  }
}
export const getCurrencyFormat = (
  number: { toString: () => string },
  options = {}
) => {
  const defaultOptions = { style: 'currency', currency: 'INR' }
  const finalOptions: any = { ...defaultOptions, ...options }

  if (number) {
    const onlyNumbers = number.toString().replace(/,/g, '')
    return Intl.NumberFormat('en-IN', finalOptions).format(Number(onlyNumbers))
  }
  return ''
}
export const getFiscalYear = (type: string) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const previousYear = currentYear - 1
  let fiscalyear
  if (type === 'current') {
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + '-' + format(today, 'yy')
    } else {
      fiscalyear =
        today.getFullYear() +
        '-' +
        format(new Date().setFullYear(currentYear + 1), 'yy')
    }
    return fiscalyear
  } else {
    if (today.getMonth() + 1 <= 3) {
      fiscalyear =
        previousYear - 1 + '-' + format(new Date(previousYear, 3, 1), 'yy')
    } else {
      fiscalyear =
        previousYear + '-' + format(new Date(currentYear, 3, 1), 'yy')
    }
    return fiscalyear
  }
}
export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
// example of how to use the function

//  const customAttributes = {
//    "Event Status": 'Pass',
//    "Age": '23',
//    "Gender": "Male"
//  }
//  triggerCleverTapEvent('Event_Name', customAttributes)
export const triggerCleverTapEvent = (
  eventName: string,
  customAttributes = {}
) => {
  const { deviceName, osType } = AppStore.getState().device
  const systemAttributes = {
    'App Name': ApplicationMeta.name,
    'App Version': ApplicationMeta.version,
    'Device Type': 'Web',
    'Name of Device':
      deviceName ||
      Bowser.parse(window.navigator.userAgent).browser.name +
        ' ' +
        Bowser.parse(window.navigator.userAgent).browser.version ||
      'Not Fetched',
    'OS Type':
      osType ||
      Bowser.parse(window.navigator.userAgent).os.name +
        ' ' +
        Bowser.parse(window.navigator.userAgent).os.version ||
      'Not Fetched',
    'Event Time': new Date().toISOString()
  }

  const eventData = { ...systemAttributes, ...customAttributes }

  if (USE_CLEVERTAP === 'true') window?.clevertap?.event.push(eventName, eventData)
}
