import React from 'react'

// DS Components
import {
  DsBox,
  DsIconButton,
  DsPopup,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

// Lib
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getSSOValidateData } from '~/src/Redux/SSO/Selectors'
import ssoCompleteAction from '~/src/Redux/SSO/Services/ssoComplete.Service'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { WHATS_CONST } from './constants'

type ActionTypes = {
  SSOComplete: (data: any) => any
}

export interface IMobileHeaderProps extends IWithRouterProps {
  searchParams: any
  actions: ActionTypes
  handleError: any
  tokenDetails: any
}
interface IMobileHeaderState {
  openWhatsThis: boolean
}

class MobileHeader extends React.Component<
  IMobileHeaderProps,
  IMobileHeaderState
> {
  state = {
    openWhatsThis: false
  }
  handleGetHeading = () => {
    const route = window.location.pathname
    if (route === '/contract-notes') {
      return 'Contract Notes'
    }
    if (route === '/ledger-report') {
      return 'Ledger Report'
    }
  }

  handleCompleteSSO = async () => {
    const { actions, tokenDetails } = this.props
    const { refreshToken = {}, sessionId, metadata } = tokenDetails

    const { actionType = '' } = metadata

    const requestPayload = {
      sessionId,
      refreshToken: refreshToken.token,
      metadata: {
        actionType
      }
    }

    const response = await actions.SSOComplete(requestPayload)

    window.open(response.redirectURL, '_self')

    if (response._isCustomError) {
      return this.handleNavigateToSomethingWentWrong()
    }
  }

  handleNavigateToSomethingWentWrong = () => {
    const { navigateTo } = this.props
    const routeObject = APP_ROUTES.SOMETHING_WENT_WRONG
    const { pathname } = routeObject
    navigateTo(pathname)
  }
  handleCloseWhatsThis = () => {
    this.setState({ openWhatsThis: false })
  }

  render() {
    const { openWhatsThis } = this.state
    const { location } = this.props
    const { pathname } = location
    return (
      <>
        <DsBox
          sx={{
            display: { xs: 'flex', md: 'none' },
            p: 'var(--ds-spacing-bitterCold)',
            gap: 'var(--ds-spacing-bitterCold)',
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow:
              'var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-deepFreeze) var(--ds-spacing-zero) rgba(0, 0, 0, 0.10)'
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}
          >
            <DsIconButton onClick={this.handleCompleteSSO}>
              <DsRemixIcon className="ri-arrow-left-line" />
            </DsIconButton>
            <DsTypography variant="headingBoldSmall">
              {this.handleGetHeading()}
            </DsTypography>
          </DsBox>
          <DsBox>
            <DsTypography
              variant="bodyBoldSmall"
              sx={{
                color: '#ED1164'
              }}
              onClick={() => this.setState({ openWhatsThis: true })}
            >
              What's this
            </DsTypography>
          </DsBox>
        </DsBox>
        <DsPopup
          open={openWhatsThis}
          onClose={this.handleCloseWhatsThis}
          sx={{
            '.MuiDialogContent-root': {
              marginTop: 'var(--ds-spacing-zero)'
            },
            display: { md: 'none', xs: 'block' }
          }}
        >
          <DsBox
            sx={{ display: 'flex', flexDirection: 'column', p: '2rem 1rem' }}
          >
            <DsTypography variant="headingBoldMedium">
              {pathname == '/ledger-report'
                ? WHATS_CONST.LEDGER.title
                : WHATS_CONST.CONTRACT.title}
            </DsTypography>
            <DsTypography sx={{ pt: '2rem' }} variant="bodyRegularSmall">
              {pathname == '/ledger-report'
                ? WHATS_CONST.LEDGER.discription
                : WHATS_CONST.CONTRACT.discription}
            </DsTypography>
          </DsBox>
        </DsPopup>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const tokenDetails = getSSOValidateData(state)
  return {
    tokenDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SSOComplete: (data: any) => dispatch(ssoCompleteAction(data))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeader)
