import { combineReducers, ReducersMapObject } from 'redux'

import ThemeReducer from './Theme/Reducer'
import { SLICE_NAME as ThemeSliceName } from './Theme/Selectors'

import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'

import LedgerReportReducer from './LedgerReport/Reducer'
import { SLICE_NAME as LedgerReportSliceName } from './LedgerReport/Selectors'

import SSOReducer, { ssoPersistConfig } from './SSO/Reducer'
import { SLICE_NAME as SSOSliceName } from './SSO/Selectors'

import ErrorReducer from './Error/Reducer'
import { SLICE_NAME as ErrorSliceName } from './Error/Selectors'

import ContractNoteReducer from './ContractNote/Reducer'
import { SLICE_NAME as ContractNoteSliceName } from './ContractNote/Selectors'

import DeviceReducer from './Device/Reducer'
import { SLICE_NAME as DeviceSliceName } from './Device/Selectors'
import CustomerReducer from './Customer/Reducer'
import { SLICE_NAME as CustomerSliceName } from './Customer/Selectors'

import { persistReducer } from 'redux-persist'

const reducers: ReducersMapObject = {
  [ThemeSliceName]: ThemeReducer,
  [AuthSliceName]: AuthReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [LedgerReportSliceName]: LedgerReportReducer,
  [ContractNoteSliceName]: ContractNoteReducer,
  [ErrorSliceName]: ErrorReducer,
  [SSOSliceName]: persistReducer(ssoPersistConfig, SSOReducer),
  [DeviceSliceName]: DeviceReducer,
  [CustomerSliceName]: CustomerReducer
}

const persistedReducers = [
  ThemeSliceName
  // AuthSliceName,
  // LedgerReportSliceName,
  // ContractNoteSliceName,
  // SSOSliceName
]

export default combineReducers(reducers)
export { persistedReducers }
