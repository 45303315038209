import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'contractNote'

const select = (state: any) => state[SLICE_NAME]
const contractNoteEquitiesSelect = (state: any) =>
  state[SLICE_NAME].contractNoteEquities
const contractNoteCommoditiesSelect = (state: any) =>
  state[SLICE_NAME].contractNoteCommodities

export const getContractNoteCommodities = createSelector(
  contractNoteCommoditiesSelect,
  contractNoteCommodities => contractNoteCommodities
)
export const getContractNoteEquities = createSelector(
  contractNoteEquitiesSelect,
  contractNoteEquities => contractNoteEquities
)
