import React from 'react'
import { PureComponent } from 'react'
import {
  DsBox,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { connect } from 'react-redux'
import withRouter from '~/src/Lib/withRouter'
import { getErrorCodeSelector } from '~/src/Redux/Error/Selectors'
import { SOMETHING_WENT_WRONG } from '~/src/Constants/ASSET_MAP'

type ActionTypes = {
  ssoInitiate: (data: any) => void
}
export interface ISomethingWentWrongErrorProps {
  errorCode: any
  actions: ActionTypes
}
class SomethingWentWrongError extends PureComponent<ISomethingWentWrongErrorProps> {
  handleRenderImage = () => {
    const { errorCode } = this.props
    if ([1082, 1083, 1084, 1085].indexOf(errorCode) > -1) {
      return (
        <DsBox>
          <DsImage
            src={
              new URL(
                '~/public/assets/images/technicalissue.svg',
                import.meta.url
              ).href
            }
          />
        </DsBox>
      )
    }
    return (
      <DsBox>
        <DsImage srcSet={SOMETHING_WENT_WRONG} />
      </DsBox>
    )
  }

  handleRenderHeading = () => {
    const { errorCode } = this.props
    if (errorCode === '1082') {
      return 'Please Restart The Application'
    }
    if (errorCode === '1084') {
      this.handleRedirectToLogin()
      return 'Session Expired!'
    }
    if (errorCode === '1085') {
      return 'Session was Unsuccessful'
    }
    return 'Something Went Wrong'
  }

  handleRenderDescription = () => {
    const { errorCode } = this.props
    if (errorCode === '1082') {
      return 'Oops! It seems like there was a technical error. Please restart the app.'
    }
    if (errorCode === '1084') {
      return 'Oops! It seems like your current session was expired. Please close this page and try again.'
    }
    if (errorCode === '1085') {
      return 'Oops! It seems like your current session was unsuccessful. Either the data was incomplete or invalid. Please close and try again.'
    }
    return "We're trying to figure out this issue. Please close this tab and try again."
  }

  handleRedirectToLogin = async () => {
    const { actions } = this.props
    const requestPayload = {
      type: 'LOGIN',
      redirectURL:
        'https://stackoverflow.com/questions/20234104/how-to-format-current-time-using-a-yyyymmddhhmmss-format',
      metadata: {
        key: 'value'
      }
    }
    const response: any = await actions.ssoInitiate(requestPayload)

    const { redirectURL = '' } = response
    setTimeout(() => {
      window.open(redirectURL, '_self')
    }, 3000)
  }

  render() {
    return (
      <DsStack
        spacing="var(--ds-spacing-frostbite)"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
          border: {
            md: '1px solid var(--ds-colour-strokeDefault)',
            sx: 'none'
          },
          borderRadius: 'var(--ds-spacing-glacial)',
          padding: 'var(--ds-spacing-mild)'
        }}
      >
        <DsBox sx={{ height: 'fit-content' }}>{this.handleRenderImage()}</DsBox>
        <DsTypography variant="headingBoldLarge">
          {this.handleRenderHeading()}
        </DsTypography>
        <DsTypography variant="bodyRegularLarge">
          {this.handleRenderDescription()}
        </DsTypography>
      </DsStack>
    )
  }
}
const mapStateToProps = (state: any) => {
  const errorCode = getErrorCodeSelector(state)
  return {
    errorCode
  }
}
const mapDispatchToProps = (dispatch: (arg0: any) => any) => {}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongError)
)
