import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { ssoCompleteActions, ssoCompleteServiceName } from '../Actions'
import { getRequiredDataForHeaders } from '~/src/Utils/global'

export interface ssoCompletePayload {
  sessionId: string
  refreshToken: string
  metadata?: null
}

async function ssoComplete(reqData: ssoCompletePayload) {
  const options = {
    url: `/sso/complete`,
    method: 'POST',
    data: reqData,
    headers: getRequiredDataForHeaders()
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const ssoCompleteAction = serviceActionCreator(ssoCompleteActions, ssoComplete)

export default ssoCompleteAction
export { ssoCompleteServiceName }
