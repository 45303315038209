import React, { Component, Suspense } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WEB_HTTP_CONTEXT } from '@am92/web-http'
import Bowser from 'bowser'

import Loader from './Components/Loader'

import AppRouter from './AppRouter'

import {
  getAccessTokenSelector,
  getRefreshTokenSelector
} from './Redux/Auth/Selectors'
import performHandshake from './Services/performHandshake'

import { TAppDispatch, TAppStore } from './Configurations/AppStore'
import { asHttp } from './Configurations/WebHttp'
import { setDeviceDetails } from './Redux/Device/Reducer'

export interface IAppInitializerProps extends PropsFromRedux {}

export interface IAppInitializerState {
  hasError: boolean
  isLoading: boolean
  handshakeComplete: boolean
}

const DEFAULT_STATE: IAppInitializerState = {
  hasError: false,
  isLoading: false,
  handshakeComplete: false
}

class AppInitializer extends Component<
  IAppInitializerProps,
  IAppInitializerState
> {
  state = DEFAULT_STATE

  async componentDidMount() {
    this.setTokensIfExist()
    this.setDeviceDetails()
    await this.initialize()
  }

  initialize = async () => {
    this.setState({ isLoading: true })
    try {
      await performHandshake()
      this.setState({ handshakeComplete: true, isLoading: false })
      // Handle All Your app Level Initialisations here
    } catch (error) {
      // Handle Error Appropriately or wrap in error boundary
      console.log('AppInitializer error', error)
      this.setState({ hasError: true, isLoading: false })
    }
  }

  setTokensIfExist = () => {
    const { accessToken, refreshToken } = this.props

    if (accessToken) {
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken)
    }

    if (refreshToken) {
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken)
    }
  }

  setDeviceDetails = () => {
    const { actions } = this.props
    actions.setDeviceDetails({
      deviceName:
        Bowser.parse(window.navigator.userAgent).browser.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).browser.version ||
        'Not Fetched',
      osType:
        Bowser.parse(window.navigator.userAgent).os.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).os.version || 'Not Fetched'
    })
  }

  render() {
    const { handshakeComplete, hasError, isLoading } = this.state
    return (
      <Suspense fallback={<Loader />}>
        {(!hasError && !isLoading && handshakeComplete && <AppRouter />) ||
          null}
      </Suspense>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const accessToken = getAccessTokenSelector(state)
  const refreshToken = getRefreshTokenSelector(state)
  return {
    accessToken,
    refreshToken
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setDeviceDetails: (data: any) => dispatch(setDeviceDetails(data))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AppInitializer)
