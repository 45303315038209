import React, { Component } from 'react'
import {
  DsBox,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

// constants
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { SIDEBAR_CONSTANTS } from '~/src/Constants/SIDEBAR_CONSTANTS'

// lib
import withRouter, { WithRouter } from '~/src/Lib/withRouter'
import { triggerCleverTapEvent } from '~/src/Utils/global'

export interface ISidebarProps extends WithRouter {
  navigateTo: any
}

export const checkIfActiveLink = (item: string) => {
  return window.location.pathname === item
}

class Sidebar extends Component<ISidebarProps> {
  handleNavigateToRoute = (type: any) => {
    const { navigateTo } = this.props
    if (type === 'Ledger Report') {
      const customAttributes = {
        'Event Status': 'Pass',
        'Screen Name': 'Menu_Ledger_Report',
        Section: 'Equities',
        Action: 'All'
      }
      triggerCleverTapEvent('Reports', customAttributes)
      navigateTo(APP_ROUTES.LEDGER_REPORTS.pathname)
    }
    if (type === 'Contract Notes') {
      const customAttributes = {
        'Event Status': 'Pass',
        'Screen Name': 'Menu_Contract_Notes',
        Section: 'Equities',
        Action: ''
      }
      triggerCleverTapEvent('Reports', customAttributes)
      navigateTo(APP_ROUTES.CONTRACT_NOTE.pathname)
    }
  }

  render() {
    return (
      <>
        <DsStack sx={{ width: '100%' }}>
          {SIDEBAR_CONSTANTS.map(item => {
            return (
              <DsBox
                key={item.key}
                onClick={() => this.handleNavigateToRoute(item.heading)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '1rem',
                  cursor: 'pointer',
                  outline: checkIfActiveLink(item.path)
                    ? '1px solid var(--ds-colour-strokeSelected)'
                    : 'none'
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px'
                  }}
                >
                  <DsBox
                    sx={{
                      display: 'flex',
                      padding: ' 8px',
                      alignItems: 'flex-start',
                      gap: '10px',
                      borderRadius: '4px',
                      border: ' 1px solid var(--ds-colour-strokeDefault)',
                      background: 'var(--ds-colour-surfaceSecondary)'
                    }}
                  >
                    <DsRemixIcon className={item.icon} />
                  </DsBox>
                  <DsTypography variant='bodyRegularLarge'>
                    {item.heading}
                  </DsTypography>
                </DsBox>

                <DsRemixIcon className='ri-arrow-right-s-line' />
              </DsBox>
            )
          })}
        </DsStack>
      </>
    )
  }
}

export default withRouter(Sidebar)
