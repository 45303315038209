import { DsButtonDefaultProps } from './DsButton.Types';
import STATE_STYLES from '../../Theme/STATE_STYLES';
export const DsButtonOverrides = {
    MuiButton: {
        defaultProps: DsButtonDefaultProps,
        variants: [
            {
                props: { variant: 'flushed' },
                style: {
                    borderRadius: 'var(--ds-radius-zero)'
                }
            },
            {
                props: {
                    variant: 'flushed',
                    color: 'primary'
                },
                style: {
                    color: 'var(--ds-colour-typoOnSurface)',
                    backgroundColor: 'var(--ds-colour-actionPrimary)',
                    '&:disabled': {
                        color: 'var(--ds-colour-typoOnSurface)',
                        backgroundColor: 'var(--ds-colour-stateUnselectedDefault)'
                    },
                    ...STATE_STYLES.ACTION_PRIMARY_STATE_PRIMARY
                }
            }
        ],
        styleOverrides: {
            root: {
                borderRadius: 'var(--ds-radius-glacial)',
                textTransform: 'none',
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'all'
                }
            },
            contained: {
                '&.MuiButton-containedPrimary': {
                    color: 'var(--ds-colour-typoOnSurface)',
                    backgroundColor: 'var(--ds-colour-actionPrimary)',
                    '&:disabled': {
                        color: 'var(--ds-colour-typoOnSurface)',
                        backgroundColor: 'var(--ds-colour-stateUnselectedDefault)'
                    },
                    ...STATE_STYLES.ACTION_PRIMARY_STATE_PRIMARY
                },
                '&.MuiButton-containedSecondary': {
                    backgroundColor: 'var(--ds-colour-surfaceSecondary)',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'var(--ds-colour-strokeDefault)',
                    color: 'var(--ds-colour-typoActionPrimary)',
                    '&:disabled': {
                        backgroundColor: 'var(--ds-colour-stateDisabledSurface)',
                        color: 'var(--ds-colour-typoDisabled)'
                    },
                    ...STATE_STYLES.SURFACE_SECONDARY_STATE_PRIMARY
                }
            },
            sizeLarge: {
                padding: 'var(--ds-spacing-bitterCold)',
                fontWeight: 'var(--ds-typo-bodyBoldLarge-fontWeight)',
                fontSize: 'var(--ds-typo-bodyBoldLarge-fontSize)',
                lineHeight: 'var(--ds-typo-bodyBoldLarge-lineHeight)',
                letterSpacing: 'var(--ds-typo-bodyBoldLarge-letterSpacing)'
            },
            sizeMedium: {
                padding: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
                fontWeight: 'var(--ds-typo-bodyBoldMedium-fontWeight)',
                fontSize: 'var(--ds-typo-bodyBoldMedium-fontSize)',
                lineHeight: 'var(--ds-typo-bodyBoldMedium-lineHeight)',
                letterSpacing: 'var(--ds-typo-bodyBoldMedium-letterSpacing)'
            },
            sizeSmall: {
                padding: 'var(--ds-spacing-glacial) var(--ds-spacing-bitterCold)',
                fontWeight: 'var(--ds-typo-bodyBoldSmall-fontWeight)',
                fontSize: 'var(--ds-typo-bodyBoldSmall-fontSize)',
                lineHeight: 'var(--ds-typo-bodyBoldSmall-lineHeight)',
                letterSpacing: 'var(--ds-typo-bodyBoldSmall-letterSpacing)'
            },
            icon: {
                '&.MuiButton-sizeLarge': {
                    fontSize: 'var(--ds-typo-fontSizeMild)'
                },
                '&.MuiButton-sizeMedium': {
                    fontSize: 'var(--ds-typo-fontSizeCool)'
                },
                '&.MuiButton-sizeSmall': {
                    fontSize: 'var(--ds-typo-fontSizeBitterCold)'
                }
            },
            startIcon: {
                marginRight: 'var(--ds-spacing-glacial)'
            },
            endIcon: {
                marginLeft: 'var(--ds-spacing-glacial)'
            },
            text: {
                padding: 'var(--ds-spacing-glacial) var(--ds-spacing-quickFreeze)',
                textTransform: 'uppercase',
                borderRadius: 'var(--ds-radius-zero)',
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '> .MuiTouchRipple-root': {
                    display: 'none'
                },
                '&:disabled': {
                    color: 'var(--ds-colour-typoDisabled)'
                },
                '> .MuiIcon-root': {
                    fontSize: 'var(--ds-typo-fontSizeFrostbite)'
                },
                '&.MuiButton-sizeLarge': {
                    fontWeight: 'var(--ds-typo-supportBoldTextButton-fontWeight)',
                    fontSize: 'var(--ds-typo-supportBoldTextButton-fontSize)',
                    lineHeight: 'var(--ds-typo-supportBoldTextButton-lineHeight)',
                    letterSpacing: 'var(--ds-typo-supportBoldTextButton-letterSpacing)'
                },
                '&.MuiButton-sizeMedium': {
                    fontWeight: 'var(--ds-typo-supportBoldTextButton-fontWeight)',
                    fontSize: 'var(--ds-typo-supportBoldTextButton-fontSize)',
                    lineHeight: 'var(--ds-typo-supportBoldTextButton-lineHeight)',
                    letterSpacing: 'var(--ds-typo-supportBoldTextButton-letterSpacing)'
                },
                '&.MuiButton-sizeSmall': {
                    fontWeight: 'var(--ds-typo-supportBoldTextButton-fontWeight)',
                    fontSize: 'var(--ds-typo-supportBoldTextButton-fontSize)',
                    lineHeight: 'var(--ds-typo-supportBoldTextButton-lineHeight)',
                    letterSpacing: 'var(--ds-typo-supportBoldTextButton-letterSpacing)'
                }
            }
        }
    }
};
