import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { enqueueNotistack } from '@am92/react-design-system'

// utils
import withRouter, { WithRouter } from '~/src/Lib/withRouter'

// actions
import { clearErrorAction, setErrorAction } from '~/src/Redux/Error/Actions'

// constants
import { HOC_HANDLED_ERROR_CODES, getErrorObj } from '~/src/Constants/ERROR_MAP'
import { getErrorObjSelector } from '~/src/Redux/Error/Selectors'
import ErrorDialog from '~/src/Components/ErrorDialog'
import { To } from 'react-router-dom'

type ActionTypes = {
  setError: (errorObj: any) => any
  clearError: () => any
}

export interface IErrorNotifierHandlerProps extends WithRouter {
  errorObj: any
  actions: ActionTypes
}

export default function withErrorConnect(
  mapStateToProps: (state: any) => any | null | undefined,
  mapDispatchToProps: (state: any) => any | null | undefined
) {
  const mapStateToPropsWrapper = (state: any) => {
    const componentState = mapStateToProps && mapStateToProps(state)
    const errorObj = getErrorObjSelector(state)
    return {
      errorObj,
      ...componentState
    }
  }

  const mapDispatchToPropsWrapper = (dispatch: any) => {
    const componentActions = mapDispatchToProps && mapDispatchToProps(dispatch)
    return {
      actions: {
        setError: (errorObj: any) => dispatch(setErrorAction(errorObj)),
        clearError: () => dispatch(clearErrorAction()),
        ...componentActions.actions
      }
    }
  }

  return function withErrorNotifierHandler(
    WrappedComponent: React.ElementType<any>
  ) {
    class ErrorNotifierHandler extends PureComponent<IErrorNotifierHandlerProps> {
      formRef = React.createRef<any>()

      handleError = async (res: any) => {
        const { actions, navigateTo } = this.props
        const { error = {} } = res

        const { code = '' } = error
        const errorObj = getErrorObj(code)

        if (errorObj && errorObj.type === 'FIELD_ERROR') {
          if (this.formRef.current && this.formRef.current.setFieldError) {
            const { fieldErrors } = errorObj

            const touchedObj = Object.keys(fieldErrors).reduce(
              (acc, fieldError: any) => {
                acc[fieldError] = true
                return acc
              },
              {} as any
            )

            const updatedFieldErrors = Object.keys(fieldErrors).reduce(
              (acc, fieldError: any) => {
                if (typeof fieldErrors[fieldError] === 'function') {
                  acc[fieldError] = fieldErrors[fieldError](error)
                } else {
                  acc[fieldError] = fieldErrors[fieldError]
                }
                return acc
              },
              {} as any
            )

            await this.formRef.current.setTouched(touchedObj)
            await this.formRef.current.setErrors(updatedFieldErrors)
          }
          return
        }

        if (errorObj && errorObj.type === 'DIALOG') {
          actions.setError(errorObj)
          return
        }

        if (errorObj && errorObj.type === 'NOTISTACK_NAVIGATION') {
          enqueueNotistack(
            errorObj.notification || { message: 'Something Went Wrong.' }
          )
          actions.setError(errorObj)
          navigateTo(errorObj?.navigation as To)
          return
        }

        if (errorObj && errorObj.type === 'NOTISTACK') {
          enqueueNotistack(
            errorObj.notification || { message: 'Something Went Wrong.' }
          )
          actions.setError(errorObj)
          return
        }

        if (errorObj && errorObj.type === 'NAVIGATION') {
          actions.setError(errorObj)
          navigateTo(errorObj?.navigation as To)
          return
        }

        enqueueNotistack({
          message: 'Something Went Wrong.'
        })
      }

      handleExternalLink = (data: { url: string }) => {
        if (data && data.url) {
          window.open(data.url)
        }
      }

      handleLink = (data: { pathname: string }) => {
        if (data && data.pathname) {
          const { navigateTo } = this.props
          navigateTo(data.pathname)
        }
      }

      handleCloseDialog = async () => {
        const { actions } = this.props
        await actions.clearError()
      }

      handleRestForm = async () => {
        if (
          this.formRef.current &&
          this.formRef.current.resetForm &&
          typeof this.formRef.current.resetForm === 'function'
        ) {
          await this.formRef.current.resetForm()
        }
      }

      checkIfApiCallError = (actions: any[]): boolean => {
        const isApiCall = actions.find(action => action.type === 'API_CALL')
        return !!isApiCall
      }

      handlePrimaryButtonClick = () => {
        const { errorObj = {} } = this.props
        const { dialog = {} } = errorObj
        const { primaryButtonClickActions = [] } = dialog
        const checkIfApiCallError = this.checkIfApiCallError(
          primaryButtonClickActions
        )

        if (!checkIfApiCallError) {
          this.handleActions(primaryButtonClickActions)
        }
      }

      handleSecondaryButtonClick = () => {
        const { errorObj = {} } = this.props
        const { dialog = {} } = errorObj
        const { secondaryButtonClickActions = [] } = dialog
        const checkIfApiCallError = this.checkIfApiCallError(
          secondaryButtonClickActions
        )

        if (!checkIfApiCallError) {
          this.handleActions(secondaryButtonClickActions)
        }
      }

      handleActions = (actions: any[]) => {
        actions.forEach((action: any) => {
          switch (action.type) {
            case 'EXTERNAL_LINK': {
              this.handleExternalLink(action.data)
            }
            case 'LINK': {
              this.handleLink(action.data)
            }
            case 'CLOSE': {
              this.handleCloseDialog()
            }
            case 'RESET_FORM': {
              this.handleRestForm()
            }
          }
        })
      }

      render() {
        const { errorObj } = this.props
        const { errorCode } = errorObj
        return (
          <>
            <WrappedComponent
              {...this.props}
              formRef={this.formRef}
              handleError={this.handleError}
              handlePrimaryButtonClick={this.handlePrimaryButtonClick}
              handleSecondaryButtonClick={this.handleSecondaryButtonClick}
            />
            {HOC_HANDLED_ERROR_CODES.includes(errorCode) &&
              errorObj.type === 'DIALOG' && (
                <ErrorDialog
                  {...(getErrorObj(errorCode) as any)}
                  primaryButtonClick={this.handlePrimaryButtonClick}
                  secondaryButtonClick={this.handleSecondaryButtonClick}
                  onClose={this.handleCloseDialog}
                />
              )}
          </>
        )
      }
    }

    return withRouter(
      connect(
        mapStateToPropsWrapper,
        mapDispatchToPropsWrapper
      )(ErrorNotifierHandler)
    )
  }
}
