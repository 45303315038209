import { WEB_HTTP_CONTEXT } from '@am92/web-http'
import { HANDSHAKE_ENABLED_HTTP } from '~/src/Configurations/WebHttp'
import { AS_GALAXY_API_DOMAIN, COMMON_HEADERS } from '~/src/Configurations/env'

const performHandshake = async () => {
  const promises = HANDSHAKE_ENABLED_HTTP.map(handshake)
  const responses: any = await Promise.allSettled(promises)
  responses.forEach((response: { status: string; reason: any }) => {
    if (response.status === 'rejected') {
      throw response.reason
    }
  })
}

const handshake: any = async (webHttp: {
  context: {
    get: (arg0: string) => any
    set: (arg0: string, arg1: any) => void
  }
  request: (arg0: { url: string; method: string; headers: any }) => any
}) => {
  if (!webHttp) {
    return
  }

  const options = {
    url: `/handshake`,
    method: 'GET',
    headers: COMMON_HEADERS
  }

  try {
    const storedPublicKey = webHttp.context.get('PUBLIC_KEY')
    if (storedPublicKey) {
      return
    }

    const response = await webHttp.request(options)
    const { data: body } = response
    const { data = {} } = body
    const { publicKey = '' } = data
    webHttp.context.set(WEB_HTTP_CONTEXT.PUBLIC_KEY, publicKey)
  } catch (err) {
    throw err
  }
}

export default performHandshake
