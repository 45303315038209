import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { ledgerReportActions, ledgerReportClearActions } from './Actions'
import { SLICE_NAME } from './Selectors'
import {
  _floor,
  _forEach,
  _get,
  _groupBy,
  _mapValues,
  _set,
  _sortBy
} from '~/src/Utils/lodash'
export interface LedgerReportStore {
  loading: boolean
  report: any
  error: any
}
const COMMODITIES = ['Commodities']
const report = {
  openingBalance: '236792.49',
  emarginBalance: '0',
  totalDebitAmount: 478405.96,
  totalCreditAmount: 478405.96,
  ledger: [
    {
      transactionDate: '20-JUL-18',
      exchange: 'BSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/N035476',
      transactionType: 'C'
    },
    {
      transactionDate: '20-JUL-18',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'N/1819081/20-JUL-2018-/-CNC',
      debitAmount: 4939.2,
      creditAmount: 4939.2,
      transactionType: 'D'
    },
    {
      transactionDate: '20-JUL-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'Online Trf for Net Payin from Bank A/c165249',
      transactionType: 'C'
    },
    {
      transactionDate: '20-JUL-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'Online Trf for Net Payin from Bank A/c165249',
      transactionType: 'C'
    },
    {
      transactionDate: '20-JUL-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'Online Trf for Net Payin from Bank A/c165249',
      transactionType: 'C'
    },
    {
      transactionDate: '20-JUL-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'Clear Funds Transfer From BSE-E To MFD-N',
      transactionType: 'C'
    },
    {
      transactionDate: '20-JUL-18',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'Clear Funds Transfer From BSE-E To MFD-N',
      debitAmount: 4939.2,
      creditAmount: 4939.2,
      transactionType: 'D'
    },
    {
      transactionDate: '20-JUL-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'N/Trade Bill-20-JUL-2018',
      debitAmount: 15000,
      creditAmount: 15000,
      transactionType: 'D'
    },
    {
      transactionDate: '23-JUL-18',
      exchange: 'BSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/N035536',
      transactionType: 'C'
    },
    {
      transactionDate: '26-JUL-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'Online Trf for Net Payin from Bank A/c165249',
      transactionType: 'C'
    },
    {
      transactionDate: '26-JUL-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'N/Trade Bill-26-JUL-2018',
      debitAmount: 1141.6,
      creditAmount: 1141.6,
      transactionType: 'D'
    },
    {
      transactionDate: '11-SEP-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'N/Trade Bill-11-SEP-2018',
      debitAmount: 15424.8,
      creditAmount: 15424.8,
      transactionType: 'D'
    },
    {
      transactionDate: '18-SEP-18',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'Reverse Bill/N/Trade Bill-18-SEP-2018',
      transactionType: 'C'
    },
    {
      transactionDate: '21-NOV-18',
      exchange: 'NSE',
      segment: 'Derivative',
      description: 'Net Payout to Bank A/c 546010100068004/O040864',
      debitAmount: 1306.7,
      creditAmount: 1306.7,
      transactionType: 'D'
    },
    {
      transactionDate: '21-NOV-18',
      exchange: 'NSE',
      segment: 'Derivative',
      description:
        'Payment_Gateway Entry 546010100068004AXIS BANK LTD101381190',
      transactionType: 'C'
    },
    {
      transactionDate: '21-NOV-18',
      exchange: 'NSE',
      segment: 'Derivative',
      description:
        'Payment_Gateway Entry 546010100068004AXIS BANK LTD101381138',
      transactionType: 'C'
    },
    {
      transactionDate: '22-NOV-18',
      exchange: 'NSE',
      segment: 'Derivative',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O041270',
      transactionType: 'C'
    },
    {
      transactionDate: '20-FEB-19',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2019037/20-FEB-2019-/-CNC',
      debitAmount: 4002.57,
      creditAmount: 4002.57,
      transactionType: 'D'
    },
    {
      transactionDate: '21-FEB-19',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2019038/21-FEB-2019-/-CNC',
      debitAmount: 4051.97,
      creditAmount: 4051.97,
      transactionType: 'D'
    },
    {
      transactionDate: '21-FEB-19',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2019038/21-FEB-2019-/-T+2',
      debitAmount: 19.62,
      creditAmount: 19.62,
      transactionType: 'D'
    },
    {
      transactionDate: '22-FEB-19',
      exchange: 'NSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O043961',
      transactionType: 'C'
    },
    {
      transactionDate: '25-FEB-19',
      exchange: 'NSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O043987-/-T+2',
      transactionType: 'C'
    },
    {
      transactionDate: '23-APR-19',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'N/1920017/23-APR-2019-/-CNC',
      transactionType: 'C'
    },
    {
      transactionDate: '23-APR-19',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2019077/23-APR-2019-/-CNC',
      transactionType: 'C'
    },
    {
      transactionDate: '23-APR-19',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'N/1920017/Online Valuation Debit',
      debitAmount: 77315.16,
      creditAmount: 77315.16,
      transactionType: 'D'
    },
    {
      transactionDate: '23-APR-19',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2019077/Online Valuation Debit',
      debitAmount: 19939.8,
      creditAmount: 19939.8,
      transactionType: 'D'
    },
    {
      transactionDate: '25-APR-19',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'N/1920017/25-APR-2019-/-CNC',
      debitAmount: 78225.42,
      creditAmount: 78225.42,
      transactionType: 'D'
    },
    {
      transactionDate: '20-MAY-19',
      exchange: 'BSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O045945',
      transactionType: 'C'
    },
    {
      transactionDate: '20-MAY-19',
      exchange: 'NSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O045946-/-T+2',
      transactionType: 'C'
    },
    {
      transactionDate: '20-MAY-19',
      exchange: 'NSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O045946',
      transactionType: 'C'
    },
    {
      transactionDate: '26-JUL-19',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'Bg SGST levied on delayed payment charges for 25-JUL-19',
      debitAmount: 3.39,
      creditAmount: 3.39,
      transactionType: 'D'
    },
    {
      transactionDate: '26-JUL-19',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'Bg CGST levied on delayed payment charges for 25-JUL-19',
      debitAmount: 3.39,
      creditAmount: 3.39,
      transactionType: 'D'
    },
    {
      transactionDate: '26-JUL-19',
      exchange: 'BSE',
      segment: 'Equity',
      description: 'Delayed payment charges for 25-JUL-19',
      debitAmount: 37.71,
      creditAmount: 37.71,
      transactionType: 'D'
    },
    {
      transactionDate: '09-DEC-19',
      exchange: 'BSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O051979',
      transactionType: 'C'
    },
    {
      transactionDate: '26-FEB-20',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'W/2020041/26-FEB-2020-/-CNC',
      debitAmount: 1.04,
      creditAmount: 1.04,
      transactionType: 'D'
    },
    {
      transactionDate: '20-MAR-20',
      exchange: 'MFD',
      segment: 'MFD',
      description: 'N/Trade Bill-20-MAR-2020',
      debitAmount: 21566.4,
      creditAmount: 21566.4,
      transactionType: 'D'
    },
    {
      transactionDate: '20-MAR-20',
      exchange: 'MFD',
      segment: 'MFD',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O052626',
      transactionType: 'C'
    },
    {
      transactionDate: '20-MAR-20',
      exchange: 'MFD',
      segment: 'MFD',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O052627',
      transactionType: 'C'
    },
    {
      transactionDate: '20-MAR-20',
      exchange: 'MFD',
      segment: 'MFD',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O052628',
      transactionType: 'C'
    },
    {
      transactionDate: '20-MAR-20',
      exchange: 'MFD',
      segment: 'MFD',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O052629',
      transactionType: 'C'
    },
    {
      transactionDate: '22-MAY-20',
      exchange: 'NSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/O052732',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JAN-22',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2022005/07-JAN-2022-/-CNC',
      debitAmount: 3534.64,
      creditAmount: 3534.64,
      transactionType: 'D'
    },
    {
      transactionDate: '10-JAN-22',
      exchange: 'NSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 546010100068004/Y061435',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041108',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041109',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041110',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041111',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041112',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041113',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041114',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041115',
      creditAmount: 3424.8,
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041116',
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041117',
      creditAmount: 3424.8,
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041118',
      creditAmount: 3424.8,
      transactionType: 'C'
    },
    {
      transactionDate: '07-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041120',
      transactionType: 'C'
    },
    {
      transactionDate: '08-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041122',
      creditAmount: 3424.8,
      transactionType: 'C'
    },
    {
      transactionDate: '08-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description: 'T-/2223046/Trade Bill-08-JUN-2022',
      debitAmount: 3424.8,
      creditAmount: 3424.8,
      transactionType: 'D'
    },
    {
      transactionDate: '08-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041123',
      transactionType: 'C'
    },
    {
      transactionDate: '08-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041125',
      transactionType: 'C'
    },
    {
      transactionDate: '08-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description:
        'M-Online Trf for Net Payin from Bank A/c 546010100068004/N041128',
      transactionType: 'C'
    },
    {
      transactionDate: '13-JUN-22',
      exchange: 'BSE',
      segment: 'MFE',
      description: 'T-/2223049/Trade Bill-13-JUN-2022',
      debitAmount: 283.2,
      creditAmount: 283.2,
      transactionType: 'D'
    },
    {
      transactionDate: '16-JUN-22',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2022113/16-JUN-2022-/-CNC',
      transactionType: 'C'
    },
    {
      transactionDate: '16-JUN-22',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'N/2022113/16-JUN-2022-/-MTF',
      debitAmount: 216516.83,
      creditAmount: 216516.83,
      transactionType: 'D'
    },
    {
      transactionDate: '17-JUN-22',
      exchange: 'BSE',
      segment: 'Equity',
      description:
        'Online Trf for Net Payin from Bank A/c 255010100100557/N041258',
      transactionType: 'C'
    },
    {
      transactionDate: '13-MAR-23',
      exchange: 'NSE',
      segment: 'Equity',
      description: 'Net Payout to Bank A/c 546010100068004/N000039',
      debitAmount: 6728.52,
      creditAmount: 6728.52,
      transactionType: 'D'
    },
    {
      transactionDate: '13-MAR-23',
      exchange: 'NSE',
      segment: 'Commodity',
      description: 'Net Payout to Bank A/c 546010100068004/N000039',
      debitAmount: 6728.52,
      creditAmount: 6728.52,
      transactionType: 'D'
    }
  ]
}
const INITIAL_STATE: LedgerReportStore = {
  loading: false,
  report: {},
  error: { code: 0, message: '', isDispatch: false }
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(ledgerReportActions.success, (state, { payload }) => {
      state.report = generateData(payload)
    })
    builder.addCase(ledgerReportClearActions, state => {
      state.report = {}
    })
  }
}

const ledgerReportSlice = createSlice(sliceOptions)
export default ledgerReportSlice.reducer

function generateData(data: any) {
  let { ledger, openingBalance } = data
  let groupedLedger = {}
  if (ledger) {
    ledger = ledger.map(_sanitizeLedgerItem)
    _forEach(
      _sortBy(ledger, ['transactionDate']).reverse(),
      (ledgerItem: any) => {
        const { segment, transactionType } = ledgerItem
        const key =
          segment === 'Commodity'
            ? `Commodities.${transactionType}`
            : `Equities.${transactionType}`
        const temp = _get(groupedLedger, key) || []
        if (transactionType === 'C') {
          openingBalance += ledgerItem.creditAmount || 0
        }
        if (transactionType === 'D') {
          openingBalance -= ledgerItem.debitAmount || 0
        }
        temp.push({
          ...ledgerItem,
          runningBalance: _floor(openingBalance, 2)
        })
        _set(groupedLedger, key, temp)
      }
    )
  }
  return { ...data, ledger: groupedLedger }
}

function _sanitizeLedgerItem(ledgerItem: any) {
  const { transactionDate } = ledgerItem
  return { ...ledgerItem, transactionDate: new Date(transactionDate) }
}
