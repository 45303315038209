type TSideBar = {
  key: string
  icon: string
  heading: string
  path: string
}

export const SIDEBAR_CONSTANTS: TSideBar[] = [
  {
    key: 'ledgerreport',
    icon: 'ri-user-line',
    heading: 'Ledger Report',
    path: '/ledger-report'
  },
  {
    key: 'Contract Notes',
    icon: 'ri-bank-line',
    heading: 'Contract Notes',
    path: '/contract-notes'
  }
]
