import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'
import { SLICE_NAME } from './Selectors'

export const contractNoteEquitiesServiceName = `${SLICE_NAME}/Equities`
export const getContractNoteEquitiesActions = traceActionsCreator(
  contractNoteEquitiesServiceName
)

export const contractNoteCommoditiesServiceName = `${SLICE_NAME}/Commodities`
export const getContractNoteCommoditiesActions = traceActionsCreator(
  contractNoteCommoditiesServiceName
)

export const downloadContractsServiceName = `${SLICE_NAME}/Commodities`
export const downloadContractsActions = traceActionsCreator(
  downloadContractsServiceName
)

export const contractNoteClearServiceName = 'contractNoteClear'
export const contractNoteClearActions = createAction(
  contractNoteClearServiceName
)
