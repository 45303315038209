import * as React from 'react'
import Dialog, { IDialogProps } from './Dialog'
import BottomStagger, { IBottomStaggerProps } from './BottomStagger'

export interface IErrorDialogProps {
  dialog: IDialogProps
  bottomStagger?: IBottomStaggerProps
  primaryButtonClick: () => void
  secondaryButtonClick: () => void
  onClose: () => void
}

export default class ErrorDialog extends React.PureComponent<IErrorDialogProps> {
  render() {
    const {
      dialog,
      bottomStagger,
      primaryButtonClick,
      secondaryButtonClick,
      onClose
    } = this.props
    const bottomStaggerProps = bottomStagger || dialog
    return [
      <Dialog
        {...dialog}
        primaryButtonClick={primaryButtonClick}
        secondaryButtonClick={secondaryButtonClick}
        onClose={onClose}
      />,
      <BottomStagger
        {...bottomStaggerProps}
        primaryButtonClick={primaryButtonClick}
        secondaryButtonClick={secondaryButtonClick}
        onClose={onClose}
      />
    ]
  }
}
