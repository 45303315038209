import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'customer'
const select = (state: any) => state[SLICE_NAME]

export const getCustomerReducer = (state: any) => {
  return state[SLICE_NAME]
}

//get bank details
export const getCustomerProfileSelector = createSelector(
  (state: { [x: string]: { customerProfile: any } }) =>
    state[SLICE_NAME].customerProfile,
  customerProfile => customerProfile
)