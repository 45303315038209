import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'

// layouts
import MainLayout from '~/src/Layouts/Main.Layout'
import ValidationLayout from '~/src/Layouts/Validation.Layout'

import { ErrorBoundary } from '~/src/Components/ErrorBoundary/ErrorBoundary'

import ContractNoteSkeleton from '../Pages/ContractNote/ContractNote.Skeleton'
import LedgerReportSkeleton from '../Pages/LedgerReports/LedgerReport.Skeleton'
import AppStore from './AppStore'

// constants
import APP_ROUTES from '~/src/Constants/APP_ROUTES'

import SomethingWentWrongError from '~/src/Pages/SomethingWentWrongError/SomethingWentWrongError.Page'

const HomePage = React.lazy(
  () => import('~/src/Pages/Home/Home.Container') as any
)
const LedgerReportsPage = React.lazy(
  () => import('~/src/Pages/LedgerReports/LedgerReport.Container') as any
)

const ContractNote = React.lazy(
  () => import('~/src/Pages/ContractNote/ContractNote.Container') as any
)

const isJourneyValidated = () => {
  const { ssoValidation } = AppStore.getState().sso
  const { sessionId } = ssoValidation
  const isValidated = sessionId

  if (!isValidated) {
    throw new Response('Not Found', { status: 404 })
  }

  return {}
}

const routeObj: RouteObject[] = [
  {
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.LEDGER_REPORTS.pathname,
        element: (
          <React.Suspense fallback={<LedgerReportSkeleton />}>
            <LedgerReportsPage />
          </React.Suspense>
        ),
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.CONTRACT_NOTE.pathname,
        element: (
          <React.Suspense fallback={<ContractNoteSkeleton />}>
            <ContractNote />
          </React.Suspense>
        ),
        loader: isJourneyValidated
      }
    ]
  } as RouteObject,
  {
    element: <ValidationLayout />,
    children: [
      {
        path: APP_ROUTES.HOME.pathname,
        element: <HomePage />
      },
      {
        path: APP_ROUTES.SOMETHING_WENT_WRONG.pathname,
        element: <SomethingWentWrongError />
      }
    ]
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
