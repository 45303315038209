import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const ssoInitiateServiceName = 'sso/ssoInitiate'
export const ssoInitiateActions = traceActionsCreator(ssoInitiateServiceName)

export const ssoValidateServiceName = 'sso/ssoValidate'
export const ssoValidateActions = traceActionsCreator(ssoValidateServiceName)

export const ssoCompleteServiceName = 'sso/ssoComplete'
export const ssoCompleteActions = traceActionsCreator(ssoCompleteServiceName)

export const updateCustomerDetailsAction = createAction<any, string>(
  'sso/updateCustomerDetails'
)
