import { To } from 'react-router-dom'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
type ErrorCodes =
  | 1083
  | 1085
  | 1081
  | 1801
  | 1802
  | 1803
  | 1804
  | 1805
  | 1806
  | 1807
  | 1901
  | 1808
  | 1809
  | 1810
  | 1811
  | 1902
  | 1904
  | 1906
  | 1908
  | 1910
  | 1911
  | 1815
  | 1816
  | 1817
  | 1818
  | 1903
  | 1921
  | 1019
  | 1080
  | 'RELEASE_FUND_ERROR'
type ErrorMap = {
  [key in ErrorCodes]: any
}

export interface Error {
  type?:
    | 'DIALOG'
    | 'FIELD_ERROR'
    | 'NOTISTACK'
    | 'NOTISTACK_NAVIGATION'
    | 'NAVIGATION'
  errorCode: number | string
  fieldErrors?: any
  notification?: {
    message: string
  }
  navigation?: {
    pathname: To
  }

  dialog?: {
    title: string
    descriptions: string[]
    reachOut?: {
      contactNumbers: string[]
      emails: string[]
    }
    primaryButtonLabel?: string
    secondaryButtonLabel?: string
    primaryButtonClickActions?: {
      type: 'EXTERNAL_LINK' | 'LINK' | 'CLOSE' | 'RESET_FORM'
      data?: { url?: string; pathname?: To }
    }[]
    secondaryButtonClickActions?: {
      type: string
      data?: { url?: string; pathname?: To }
    }[]
  }
  bottomStagger?: {
    buttonGroupSeperator?: 'LOGIN WITH'
    title: string
    descriptions: string[]
    reachOut?: {
      contactNumbers: string[]
      emails: string[]
    }
    primaryButtonLabel: string
    secondaryButtonLabel: string
    primaryButtonClickActions: {
      type: 'EXTERNAL_LINK' | 'LINK' | 'CLOSE' | 'RESET_FORM'
      data?: { url?: string; pathname?: To }
    }[]
    secondaryButtonClickActions: {
      type: string
      data?: { url?: string; pathname?: To }
    }[]
  }
}

const ERROR_MAP: { [key: number | string]: Error } = {
  1083: {
    type: 'NAVIGATION',
    errorCode: '1083',
    navigation: { pathname: APP_ROUTES.SOMETHING_WENT_WRONG.pathname }
  },
  1085: {
    type: 'NAVIGATION',
    errorCode: '1085',
    navigation: { pathname: APP_ROUTES.SOMETHING_WENT_WRONG.pathname }
  },
  1081: {
    type: 'NAVIGATION',
    errorCode: '1081',
    navigation: { pathname: APP_ROUTES.SOMETHING_WENT_WRONG.pathname }
  },
  1080: {
    type: 'NAVIGATION',
    errorCode: '1080',
    navigation: { pathname: APP_ROUTES.SOMETHING_WENT_WRONG.pathname }
  },
  1921: {
    type: 'NOTISTACK',
    errorCode: '1921',
    notification: {
      message: 'Request could not be processed , please try again'
    }
  },
  1903: {
    type: 'DIALOG',
    errorCode: '1903',
    dialog: {
      title: 'Failed to update the limit',
      descriptions: [
        'Any surplus funds blocked in the bank account will be released back at the end of the day'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1901: {
    type: 'DIALOG',
    errorCode: '1901',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1804: {
    type: 'DIALOG',
    errorCode: '1804',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1805: {
    type: 'DIALOG',
    errorCode: '1805',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1803: {
    type: 'DIALOG',
    errorCode: '1803',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1802: {
    type: 'DIALOG',
    errorCode: '1802',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1806: {
    type: 'DIALOG',
    errorCode: '1806',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1814: {
    type: 'DIALOG',
    errorCode: '1814',
    dialog: {
      title: 'Failed to update the limit',
      descriptions: [
        'Any surplus funds blocked in the bank account will be released back at the end of the day'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1813: {
    type: 'DIALOG',
    errorCode: '1813',
    dialog: {
      title: 'Failed to update the limit',
      descriptions: [
        'Any surplus funds blocked in the bank account will be released back at the end of the day'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1807: {
    type: 'DIALOG',
    errorCode: '1807',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1808: {
    type: 'DIALOG',
    errorCode: '1808',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1809: {
    type: 'DIALOG',
    errorCode: '1809',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1810: {
    type: 'DIALOG',
    errorCode: '1810',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1811: {
    type: 'DIALOG',
    errorCode: '1811',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1902: {
    type: 'DIALOG',
    errorCode: '1902',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1904: {
    type: 'DIALOG',
    errorCode: '1904',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1906: {
    type: 'DIALOG',
    errorCode: '1906',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1908: {
    type: 'DIALOG',
    errorCode: '1908',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1910: {
    type: 'DIALOG',
    errorCode: '1910',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1911: {
    type: 'DIALOG',
    errorCode: '1911',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1086: {
    type: 'DIALOG',
    errorCode: '1086',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Request a call',
      secondaryButtonLabel: 'Close',
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },
  1815: {
    type: 'DIALOG',
    errorCode: '1815',
    dialog: {
      title: 'Failed to update the limit',
      descriptions: [
        'Any surplus funds blocked in the bank account will be released back at the end of the day'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1816: {
    type: 'DIALOG',
    errorCode: '1816',
    dialog: {
      title: 'Failed to update the limit',
      descriptions: [
        'Any surplus funds blocked in the bank account will be released back at the end of the day'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1817: {
    type: 'DIALOG',
    errorCode: '1817',
    dialog: {
      title: 'Failed to update the limit',
      descriptions: [
        'Any surplus funds blocked in the bank account will be released back at the end of the day'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1818: {
    type: 'DIALOG',
    errorCode: '1818',
    dialog: {
      title: 'Failed to update the limit',
      descriptions: [
        'Any surplus funds blocked in the bank account will be released back at the end of the day'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  },
  1019: {
    type: 'DIALOG',
    errorCode: '1019',
    dialog: {
      title: 'Something Went Wrong',
      descriptions: [
        'We’re trying to figure out this issue. Please try again later'
      ],
      secondaryButtonLabel: 'Try Again'
    }
  }
}

//needs to verify
export const PAGE_HANDLED_ERROR_CODES = [
  1813, 1814, 1815, 1816, 1817, 1818, 1082
]

const HOC_HANDLED_ERROR_CODES: number[] = []

for (let code of Object.keys(ERROR_MAP)) {
  if (!PAGE_HANDLED_ERROR_CODES.includes(Number(code))) {
    HOC_HANDLED_ERROR_CODES.push(Number(code))
  }
}

export { HOC_HANDLED_ERROR_CODES }

export const getErrorObj = (errorCode: ErrorCodes) => {
  return ERROR_MAP[errorCode]
}
